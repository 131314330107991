export default {
  data() {
    return {
      layer: undefined,
      source: undefined,
      zoomed: false,
    };
  },
  props: {
    opacity: {
      type: Number,
      default: 1,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    zoomToExtent: Boolean,
  },
  computed: {
    map() {
      return this.$parent.olMap;
    },
    view() {
      return this.map?.getView();
    },
  },
  watch: {
    layer: {
      immediate: true,
      handler(layer, existingLayer) {
        if (layer && !existingLayer) {
          if (layer.setOpacity) {
            layer.setOpacity(this.opacity);
          }
          if (layer.setVisible) {
            layer.setVisible(this.visible);
          }
          this.map.addLayer(layer);
        }
      },
    },
    source: {
      immediate: true,
      handler(source) {
        if (!this.zoomed && source && this.zoomToExtent && source.getExtent) {
          const extent = [...(source?.getExtent() || [])]?.filter(Number.isFinite);
          if (extent && extent.length > 0) {
            this.view.fit(source.getExtent(), { maxZoom: 16, padding: [50, 50, 50, 50] });
            this.zoomed = true;
          }
        }
      },
      deep: true,
    },
    opacity: {
      immediate: true,
      handler(opacity) {
        if (this.layer?.setOpacity) {
          this.layer.setOpacity(opacity);
        }
      },
    },
    visible: {
      immediate: true,
      handler(visible) {
        if (this.layer?.setVisible) {
          this.layer.setVisible(visible);
        }
      },
    },
  },
};
