import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    modSingle: Boolean,
    modBlock: Boolean,
    modDisabled: Boolean,
    modError: Boolean,
    modSuccess: Boolean,
    modSwitch: Boolean,
    modIndeterminate: Boolean,
    value: null,
    checked: null,
  },
  model: {
    prop: 'checked',
    event: 'input',
  },
  inheritAttrs: false,
  watch: {
    modIndeterminate(value) {
      this.$el.querySelector('input').indeterminate = value;
    },
  },
  computed: {
    classes() {
      return [
        'vl-checkbox',
        {
          'vl-checkbox--block': this.modBlock,
          'vl-checkbox--single': this.modSingle,
          'vl-checkbox--disabled': this.modDisabled,
          'vl-checkbox--error': this.modError,
          'vl-checkbox--success': this.modSuccess,
          'vl-checkbox--switch': this.modSwitch,
        },
      ];
    },
    labelClasses() {
      return [
        'vl-checkbox__label',
      ];
    },
    computedValue: {
      get() {
        return this.checked;
      },
      set(value) {
        if (Array.isArray(this.checked)) {
          if (this.checked.includes(this.value)) {
            this.checked.splice(this.checked.indexOf(this.value), 1);
          } else {
            this.checked.push(this.value);
          }
        }
        this.$emit('input', value);
      },
    },
    role() {
      return this.modSwitch ? 'switch' : undefined;
    },
  },
  methods: {
    clickHandler(e) {
      if (this.modSwitch) {
        this.$el.querySelector('input').click();
      } else {
        this.$emit('click', e);
      }
    },
  },
};
