<template>
  <li :class="classes" v-show="isVisible && shouldRender" @click="select">
    <div class="vl-sidebar-panel-list-item__header" v-if="$slots.header">
      <vl-title tag-name="h5" class="vl-sidebar-panel-list-item__header__title">
        <slot name="header" />
      </vl-title>
    </div>
    <div
      class="vl-sidebar-panel-list-item__image-container"
      v-if="$slots.image"
    >
      <slot name="image" />
    </div>
    <div
      class="vl-sidebar-panel-list-item__content"
      v-if="$slots.content || hasLabel"
    >
      <div
        class="vl-sidebar-panel-list-item__link"
        v-if="hasLabel"
        v-show="!isActive"
      >
        <component :is="panelLinkTag" :class="buttonClasses">
          <slot name="label">
            <span v-html="label"></span>
          </slot>
          <vl-icon icon="nav-right" v-if="hasPanelChildren" />
        </component>
      </div>
      <slot name="content" />
    </div>
    <div class="vl-sidebar-panel-list-item__footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
    <slot />
  </li>
</template>

<script>
import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlTitle from '@govflanders/vl-ui-titles/src/vue';

export default {
  props: {
    label: String,
    href: String,
    modFullHeight: Boolean,
    active: Boolean,
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasPanelChildren: false,
    };
  },
  inject: ['activeArray'],
  components: {
    VlIcon,
    VlTitle,
  },
  computed: {
    classes() {
      return [
        'vl-sidebar-panel-list-item',
        {
          'vl-sidebar-panel-list-item--has-children': this.hasPanelChildren,
          'vl-sidebar-panel-list-item--full': this.modFullHeight,
          'vl-sidebar-panel-list-item--active': this.isActive,
        },
      ];
    },
    buttonClasses() {
      return [
        'vl-sidebar-panel-list-item__button',
        {
          'vl-sidebar-panel-list-item__button--has-children':
            this.hasPanelChildren,
        },
      ];
    },
    panelLinkTag() {
      return this.hasPanelChildren ? 'button' : 'a';
    },
    panelId() {
      return `vl-sidebar-panel-${this._uid}`;
    },
    hasActiveArrayItems() {
      return this.activeArray && this.activeArray.length > 0;
    },
    isActive() {
      return this.activeArray.includes(this.panelId);
    },
    grandParent() {
      if (this.$parent) {
        return this.$parent.$parent;
      }
      return undefined;
    },
    shouldRender() {
      if (this.isActive) {
        return true;
      }
      if (
        this.grandParent
        && this.grandParent.isActive
        && this.hasPanelChildren
      ) {
        return !this.$parent.$children.find((child) => child.isActive);
      }
      if (this.hasActiveArrayItems) {
        return (
          this.$parent.modSub && this.grandParent && this.grandParent.isActive
        );
      }
      return !this.$parent.modSub;
    },
    hasLabel() {
      return (this.$slots && this.$slots.label) || this.label;
    },
  },
  methods: {
    select() {
      if (!this.activeArray.includes(this.panelId)) {
        this.activeArray.push(this.panelId);
      }
    },
  },
  mounted() {
    const children = this.$children;
    const panelListChildren = [];

    if (!this.href) {
      children.forEach((child) => {
        if (child.$refs['panel-list']) {
          panelListChildren.push(child.$refs['panel-list']);
        }
      });
    }

    if (panelListChildren && panelListChildren.length) {
      this.hasPanelChildren = !!panelListChildren.length;
    }
  },
  watch: {
    active: {
      immediate: true,
      handler(active) {
        if (active !== this.isActive) {
          if (active && !this.activeArray.includes(this.panelId)) {
            this.activeArray.push(this.panelId);
          } else if (this.activeArray.includes(this.panelId)) {
            this.activeArray.splice(this.activeArray.indexOf(this.panelId), 1);
          }
        }
      },
    },
    isActive(isActive) {
      this.$emit('change', isActive);
    },
  },
};
</script>
