import { VlColumn } from '@govflanders/vl-ui-core/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    width: [Number, String],
    widthL: [Number, String],
    widthM: [Number, String],
    widthS: [Number, String],
    widthXs: [Number, String],
  },
  components: {
    VlColumn,
  },
  computed: {
    classes() {
      return [
        'vl-slide',
        'vl-slider__item',
      ];
    },
    tagName() {
      if (this.width || this.widthL || this.widthM || this.widthS || this.widthXs) {
        return 'vl-column';
      }
      return 'div';
    },
  },
};
