import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    label: String,
    tagName: {
      default: 'div',
      type: String,
    },
    width: [Number, String],
    widthL: [Number, String],
    widthM: [Number, String],
    widthS: [Number, String],
    widthXs: [Number, String],
    closeTitle: String,
    closeA11yLabel: String,
  },
  data() {
    return {
      isActive: false,
      tipPosition: 0,
      modFullWidth: false,
      id: '',
    };
  },

  computed: {
    classes() {
      return [
        'vl-dynamic-drawer',
        {
          'vl-dynamic-drawer--full-width': this.modFullWidth,
        },
      ];
    },
    calculatedTipPosition() {
      return this.modFullWidth ? 0 : this.tipPosition;
    },
  },

  watch: {
    isActive(value) {
      if (value) {
        setTimeout(() => {
          this.$el.querySelector('.js-vl-dynamic-drawer__close').focus();
        }, 0);
      }
    },
  },

  methods: {
    closeDrawer() {
      this.isActive = false;
      this.$emit('close');
    },
  },
};
