<template>
  <component :class="classes" :is="tagName" ref="panel-list">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tagName: {
      default: 'ul',
    },
    modSub: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-sidebar-panel-list', {
          'vl-sidebar-panel-list--sub': this.modSub,
        },
      ];
    },
  },
};
</script>
