<template>
  <component :is="infoTileType" v-bind="$attrs" @click="$emit('click', $event)">
    <slot />
    <slot slot="badge" name="badge" />
    <slot slot="subtitle" name="subtitle" />
    <slot slot="footer" name="footer" />
    <slot slot="headerextension" name="headerextension" />
  </component>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import VlInfoTileDefault from './vl-info-tile-default.vue';
import VlInfoTileCalendar from './vl-info-tile-calendar.vue';
import VlInfoTileSpotlight from './vl-info-tile-spotlight.vue';

export default {
  components: {
    VlInfoTileDefault,
    VlInfoTileCalendar,
    VlInfoTileSpotlight,
  },
  props: {
    type: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'calendar', 'spotlight'].includes(type);
      },
    },
  },
  computed: {
    infoTileType() {
      return `vl-info-tile-${this.type}`;
    },
  },
};
</script>
