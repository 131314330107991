import VlOlMapSource from '../vl-ol-map-source/vl-ol-map-source.vue';

let TileWMS;
let WMSCapabilities;

if (typeof window !== 'undefined') {
  TileWMS = require('ol/source/TileWMS').default; /* eslint-disable-line */
  WMSCapabilities = require('ol/format/WMSCapabilities').default; /* eslint-disable-line */
}

export default {
  mixins: [VlOlMapSource],
  data() {
    return {
      capabilities: {
        version: undefined,
        service: undefined,
        layers: undefined,
      },
    };
  },
  props: {
    url: String,
    version: String,
    service: String,
    layers: Array,
  },
  computed: {
    params() {
      return {
        VERSION: this.version || this.capabilities.version,
        SERVICE: this.service || this.capabilities.service,
        LAYERS: this.layers || this.capabilities.layers,
      };
    },
  },
  watch: {
    params(params) {
      this.source = new TileWMS({
        url: this.url,
        params,
        serverType: 'geoserver',
      });
    },
    url: {
      immediate: true,
      async handler() {
        const capabilities = await this.getCapabilities();
        if (capabilities) {
          this.capabilities.version = capabilities.version;
          this.capabilities.service = capabilities.Service?.Name || 'WMS';
          if (capabilities.Capability?.Layer) {
            if (capabilities.Capability.Layer?.Name) {
              this.capabilities.layers = [capabilities.Capability.Layer.Name];
            } else {
              this.capabilities.layers = capabilities.Capability.Layer.Layer.map((layer) => layer.Name);
            }
          }
        }
      },
    },
  },
  methods: {
    async getCapabilities() {
      if (WMSCapabilities) {
        const response = await fetch(this.capabilitiesUrl);
        return new WMSCapabilities().read(await response.text());
      }
      return undefined;
    },
  },
};
