import nlBE from './locale/nl-BE';

let Flickity;
let ResizeSensor;
let ImagesLoaded;

if (typeof window !== 'undefined') {
  /* eslint-disable */
  Flickity = require('flickity')
  ResizeSensor = require('resize-sensor')
  ImagesLoaded = require('imagesloaded')
  require('./helpers/flickity-equal-height')
  /* eslint-enable-line */
}

const gridClass = 'vl-grid';
const sliderClass = 'flickity-slider';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    dots: Boolean,
    keyboardNavigation: Boolean,
    arrows: Boolean,
    equalHeight: Boolean,
    modThumbnails: Boolean,
    modFullWidth: Boolean,
    a11yLabelPrevSlideButton: {
      type: String,
      default: 'Vorige slide',
    },
    a11yLabelNextSlideButton: {
      type: String,
      default: 'Volgende slide',
    },

  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-slider', {
          'vl-slider--equal-height': this.equalHeight,
          'vl-slider--thumbnails': this.modThumbnails,
          'vl-slider--full-width': this.modFullWidth,
        },
      ];
    },
    previousClasses() {
      return [
        'js-vl-slider__previous', {
          'js-vl-slider__previous--disabled': this.previousButtonDisabled,
        },
      ];
    },
    nextClasses() {
      return [
        'js-vl-slider__next', {
          'js-vl-slider__next--disabled': this.nextButtonDisabled,
        },
      ];
    },
    showPrevNextButtons() {
      return this.arrows;
    },
    options() {
      const customOptions = {
        accessibility: this.keyboardNavigation,
        pageDots: this.dots,
      };

      const options = Object.assign(this.defaultOptions, customOptions);

      return options;
    },
  },
  data() {
    return {
      previousButtonDisabled: false,
      nextButtonDisabled: false,
      defaultOptions: {
        accessibility: true,
        contain: true,
        prevNextButtons: false,
        pageDots: true,
        percentPosition: true,
        groupCells: true,
        imagesLoaded: true,
        cellAlign: 'center',
      },
    };
  },
  // Lifecycle hooks
  mounted() {
    this.initialize();

    // Resize sensor
    // eslint-disable-next-line
    const resizeSensor = new ResizeSensor(this.$el, () => {
      this.resizeSlider();
    });

    ImagesLoaded(this.$el, () => {
      this.resizeSlider();
    });
  },
  beforeDestroy() {
    this.$flickity.destroy();
    this.$flickity = null;
  },
  methods: {
    initialize() {
      this.$flickity = new Flickity(this.$refs.slides, this.options);

      this.$emit('initialize', this.$flickity);
      this.checkNavButtonState(this.selectedIndex());

      // Adding vl-grid class to generated flickity-class
      const sliderWrapper = this.$refs.slides.querySelector(`.${sliderClass}`);

      if (sliderWrapper) {
        if (!sliderWrapper.classList.contains(gridClass) && !this.modThumbnails && !this.modFullWidth) {
          sliderWrapper.classList.add(gridClass);
        }
      }

      this.on('change', () => {
        this.checkNavButtonState(this.selectedIndex());
      });
    },
    checkNavButtonState(index) {
      this.nextButtonDisabled = index === this.$flickity.slides.length - 1;
      this.previousButtonDisabled = index <= 0;
    },
    resizeSlider() {
      if (this.$flickity) {
        this.$flickity.resize();
      }
    },
    reposition() {
      this.$flickity.reposition();
    },
    selectSlide(index, wrapped, instant) {
      this.$flickity.select(index, wrapped, instant);
    },
    nextSlide(wrapped, instant) {
      this.$flickity.next(wrapped, instant);
    },
    previousSlide(wrapped, instant) {
      this.$flickity.previous(wrapped, instant);
    },
    prependSlide(elements) {
      this.$flickity.prepend(elements);
    },
    appendSlide(elements) {
      this.$flickity.append(elements);
    },
    insertSlide(elements, index) {
      this.$flickity.insert(elements, index);
    },
    removeSlide(elements) {
      this.$flickity.remove(elements);
    },
    rerenderSlider() {
      this.$flickity.destroy();
      this.init();
    },
    destroySlider() {
      this.$flickity.destroy();
    },
    on(eventName, listener) {
      this.$flickity.on(eventName, listener);
    },
    off(eventName, listener) {
      this.$flickity.off(eventName, listener);
    },
    selectedElement(multiple = true) {
      return multiple ? this.$flickity.selectElements : this.$flickity.selectedElement;
    },
    selectedIndex() {
      return this.$flickity.selectedIndex;
    },
  },
};
