import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlButton from '@govflanders/vl-ui-button/src/vue';

import debounce from 'lodash/debounce';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlButton,
  },
  props: {
    tagName: {
      type: String,
      default: 'table',
    },
    modMatrix: {
      type: Boolean,
      default: false,
    },
    modGrid: {
      type: Boolean,
      default: false,
    },
    modZebra: {
      type: Boolean,
      default: false,
    },
    modHover: {
      type: Boolean,
      default: false,
    },
    modCollapsedXs: {
      type: Boolean,
      default: false,
    },
    modCollapsedS: {
      type: Boolean,
      default: false,
    },
    modCollapsedM: {
      type: Boolean,
      default: false,
    },
    modCollapsedTruncate: {
      type: Boolean,
      default: false,
    },
    modSticky: {
      type: Boolean,
      default: false,
    },
    modOverflowNavigation: {
      type: Boolean,
      default: false,
    },
    modCaptionBottom: Boolean,
    buttonLeftLabel: {
      type: String,
    },
    buttonRightLabel: {
      type: String,
    },
  },
  data() {
    return {
      tableContainer: null,
      table: null,
      tableIsWiderAsContainer: false,
      tableIsAtScrollStart: true,
      tableScrolledTillEnd: false,
    };
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-data-table', {
          'vl-data-table--matrix': this.modMatrix,
          'vl-data-table--grid': this.modGrid,
          'vl-data-table--zebra': this.modZebra,
          'vl-data-table--hover': this.modHover,
          'vl-data-table--collapsed-xs': this.modCollapsedXs,
          'vl-data-table--collapsed-s': this.modCollapsedS,
          'vl-data-table--collapsed-m': this.modCollapsedM,
          'vl-data-table--collapsed-truncate': this.modCollapsedTruncate,
          'vl-data-table--sticky': this.modSticky,
          'vl-data-table--caption-bottom': this.modCaptionBottom,
        },
      ];
    },
    containerClasses() {
      return [
        {
          'vl-data-table--scrolled-at-start': this.modOverflowNavigation && this.tableIsAtScrollStart,
          'vl-data-table--scrolled-till-end': this.modOverflowNavigation && this.tableScrolledTillEnd,
          'vl-data-table--scrolling': this.modOverflowNavigation && !this.tableIsAtScrollStart && !this.tableScrolledTillEnd,
          'vl-u-table-overflow': this.modOverflowNavigation,
        },
      ];
    },
    labelLeft() {
      return this.buttonLeftLabel ? this.buttonLeftLabel : this.$i18n.t('buttonLeft');
    },
    labelRight() {
      return this.buttonRightLabel ? this.buttonRightLabel : this.$i18n.t('buttonRight');
    },
  },
  methods: {
    scrollRight(e) {
      try {
        this.tableContainer.scroll({
          top: 0,
          left: this.tableContainer.scrollLeft + this.tableContainer.clientWidth / 2,
          behavior: 'smooth',
        });
        this.$emit('scrollRight', e);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn({ error });
      }
    },
    scrollLeft(e) {
      try {
        this.tableContainer.scroll({
          top: 0,
          left: this.tableContainer.scrollLeft - this.tableContainer.clientWidth / 2,
          behavior: 'smooth',
        });
        this.$emit('scrollLeft', e);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn({ error });
      }
    },
    checkContainerWidth: debounce(function () {
      this.tableIsWiderAsContainer = this.table.clientWidth > this.tableContainer.clientWidth;
    }, 50),
    checkScrollPosition: debounce(function () {
      this.tableIsAtScrollStart = this.tableContainer.scrollLeft === 0;
      this.tableScrolledTillEnd = this.tableContainer.scrollWidth === this.tableContainer.scrollLeft + this.tableContainer.clientWidth;
    }, 50),

  },
  mounted() {
    this.tableContainer = this.$refs.tableContainer;
    this.table = this.$refs.tableContent;

    if (this.table && this.tableContainer) {
      this.checkContainerWidth();

      window.addEventListener('resize', this.checkContainerWidth);
    }

    if (this.tableContainer) {
      // used to remove the shadow overlay
      this.tableContainer.addEventListener('scroll', this.checkScrollPosition);
    }
  },
  beforeDestroy() {
    // Clean up
    window.removeEventListener('resize', this.checkContainerWidth);

    if (this.tableContainer) {
      this.tableContainer.removeEventListener('resize', this.checkScrollPosition);
    }
  },
};
