import VlBadge from '@govflanders/vl-ui-badge/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlBadge,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: [Object, String],
    },
    subtitle: String,
    connections: Array,
    modAlt: Boolean,
    annotation: String,
    modHighlight: Boolean,
    modClickable: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-info-tile', {
          'vl-info-tile--alt': this.modAlt,
          'vl-info-tile--clickable': this.$attrs.href || this.to || this.modClickable,
          'vl-info-tile--badge-bottom': this.connections,
          'vl-info-tile--highlight': this.modHighlight,
        },
      ];
    },
    headerClasses() {
      return [
        'vl-info-tile__header',
        {
          'vl-info-tile__header--with-extension': this.headerExtensionFilled,
        },
      ];
    },
    connectionClasses() {
      return [
        'vl-info-tile__badges__badge',
      ];
    },
    defaultSlotFilled() {
      return !!this.$slots.default;
    },
    badgeSlotFilled() {
      return !!this.$slots.badge;
    },
    subtitleSlotFilled() {
      return !!this.$slots.subtitle;
    },
    headerExtensionFilled() {
      return !!this.$slots.headerextension;
    },
    tag() {
      if (this.to) {
        return 'router-link';
      }
      if (this.$attrs.href) {
        return 'a';
      }

      return this.tagName;
    },
  },
};
