import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import VlImage from '@govflanders/vl-ui-image/src/vue/';
import VlCaption from '@govflanders/vl-ui-caption/src/vue/';
import nlBE from '../locale/nl-BE';

export default {
  inject: {
    parent: {
      default: null,
    },
  },
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlImage,
    VlCaption,
  },
  props: {
    tagName: {
      type: String,
      default: 'figure',
    },
    src: {
      type: String,
      required: true,
    },
    srcset: String,
    alt: String,
    sizes: String,
    caption: String,
    copyright: String,
    link: {
      type: String,
      required: true,
    },
    lightBoxSize: {
      type: String,
    },
    isInGallery: Boolean,
    hiddenLabel: String,
    modFill: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-lightbox__item', {
          'vl-lightbox__item--fill': this.modFill,
        },
      ];
    },
    lightboxLinkClasses() {
      return [
        'vl-lightbox__link',
        {
          'vl-gallery__item__image-wrapper': this.isInGallery,
        },
      ];
    },
    imgClasses() {
      return [{
        'vl-gallery__item__image': this.isInGallery,
      }];
    },
    computedHiddenLabel() {
      return this.hiddenLabel || this.$t('lightbox.hiddenLabel');
    },
  },
  created() {
    if (this.parent) {
      this.parent.lightboxItems.push(this);
    }
  },
  methods: {
    handleClick() {
      this.$emit('click-lightbox-item');
    },
  },
};
