import Plyr from 'plyr';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    'nl-BE': nlBE,
  },
  props: {
    /** Options object for plyr config. */
    options: {
      type: Object,
      required: false,
      default() {
        return {
          hideYouTubeDOMError: true,
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'settings',
            'pip',
            'airplay',
            'download',
            'fullscreen'],
        };
      },
    },
    /** Array of events to emit from the plyr object */
    emit: {
      type: Array,
      required: false,
      default() { return []; },
    },
    tagName: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    classes() {
      return [
        'vl-video-player',
      ];
    },
  },
  data() {
    return {
      player: {},
    };
  },
  mounted() {
    this.player = new Plyr(this.$el.firstChild, this.options);
    if (typeof this.player !== 'undefined' && typeof this.player.addEventListener === 'function') {
      this.player.addEventListener('ready', () => { // eslint-disable-line no-undef
        // fix bug for audio controls not apearing in some Chrome versions
        this.player.currentTime = 0.0000001; // eslint-disable-line no-undef
      });
    }
    this.$emit('player', this.player);
    this.emit.forEach((element) => {
      this.player.on(element, this.emitPlayerEvent);
    });
  },
  beforeDestroy() {
    try {
      if (typeof this.player.destroy === 'function') {
        this.player.destroy();
      }
    } catch (e) {
      if (!(this.options.hideYouTubeDOMError && e.message === 'The YouTube player is not attached to the DOM.')) {
        // eslint-disable-next-line
        console.error(e);
      }
    }
  },
  methods: {
    emitPlayerEvent(event) {
      this.$emit(event.type, event);
    },
  },
};
