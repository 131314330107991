import { ValidationObserver } from 'vee-validate';

export default {
  extends: ValidationObserver,
  components: { ValidationObserver },
  data() {
    return {
      jumpTo: false,
    };
  },
  methods: {
    submit(handleSubmit) {
      if (this.jumpTo && this.$el && this.$el.elements) {
        const input = [...this.$el.elements].find(element => element.hasAttribute('aria-invalid'));
        if (input) {
          input.focus();
          this.jumpTo = false;
        }
      }
      return async submit => {
        await handleSubmit(submit);
        this.jumpTo = true;
      };
    },
  },
};
