import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'label',
    },
    title: String,
    image: Object,
    active: Boolean,
    name: {
      type: String,
      default: 'base-layer-toggle',
    },
  },
  computed: {
    classes() {
      return [
        'vl-ol-map-base-layer-toggle',
      ];
    },
  },
  methods: {
    /**
     * Emit base layer toggle radio change event to parent
     * @param {Event} event
     */
    emitSelect($event) {
      this.$parent.$emit('_change', $event, $event.target.value);
    },
  },
};
