import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

import VlOlMapSource from '../vl-ol-map-source/vl-ol-map-source.vue';

let VectorSource;
let Cluster;
let OlGML2;
let GeoJSON;
let Point;
let DOMParserExists = false;

if (typeof window !== 'undefined') {
  DOMParserExists = true;
  VectorSource = require('ol/source/Vector').default; /* eslint-disable-line */
  Cluster = require('ol/source/Cluster').default; /* eslint-disable-line */
  OlGML2 = require('ol/format/GML2').default; /* eslint-disable-line */
  GeoJSON = require('ol/format/GeoJSON').default; /* eslint-disable-line */
  Point = require('ol/geom/Point').default; /* eslint-disable-line */
}

proj4.defs(
  'EPSG:31370',
  '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 '
  + '+x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs',
);
register(proj4);

export default {
  mixins: [VlOlMapSource],
  data() {
    return {
      projection: 'EPSG:31370',
      capabilities: {
        version: undefined,
        service: 'WFS',
        typename: undefined,
      },
      vectorSource: undefined,
    };
  },
  props: {
    url: String,
    version: String,
    service: String,
    layers: Array,
    clustering: Boolean,
    clusterDistance: Number,
  },
  computed: {
    params() {
      return {
        request: 'GetFeature',
        outputFormat: 'GML2',
        version: this.version || this.capabilities.version,
        service: this.service || this.capabilities.service,
        typename: this.layers || this.capabilities.layers,
      };
    },
  },
  watch: {
    vectorSource(source) {
      if (this.clustering) {
        this.source = new Cluster({
          source,
          distance: this.clusterDistance || 40,
          geometryFunction: (feature) => new Point(feature.getGeometry().getFirstCoordinate()),
        });
      } else {
        this.source = source;
      }
    },
    params(params) {
      this.vectorSource = new VectorSource({
        url: (extent, resolution, projection) => {
          const url = new URL(this.url);
          const code = projection.getCode();
          const concatenator = url.search ? '&' : '?';
          const path = `${this.url}${concatenator}service=${params.service}&request=${params.request}&typename=${params.typename}`
          + `&srsname=${code}&outputFormat=${params.outputFormat}&version=${params.version},${code}`;
          if (!url.search?.toLowerCase().includes('cql_filter')) {
            const bbox = proj4(code, this.projection, [...extent]).join(',');
            path.concat(`&bbox=${bbox}`);
          }
          return path;
        },
        format: new OlGML2(),
        strategy: bboxStrategy,
      });
    },
    url: {
      immediate: true,
      async handler(url) {
        const isGeoJSON = url.endsWith('geojson');
        if (isGeoJSON) {
          this._createGeoJSONVectorSource(url);
        } else {
          const capabilities = await this.getCapabilities();
          if (capabilities) {
            try {
              const serviceIdentification = capabilities.getElementsByTagName('ows:ServiceIdentification')[0];
              const serviceTypeVersion = serviceIdentification.getElementsByTagName('ows:ServiceTypeVersion')[0].childNodes[0].nodeValue;
              const featuresTypeList = capabilities.getElementsByTagName('FeatureTypeList');
              const featureTypes = [...featuresTypeList].map((featureType) => featureType.getElementsByTagName('FeatureType')[0]);
              const layers = [...featureTypes].map((featureType) => featureType.getElementsByTagName('Name')[0].childNodes[0].nodeValue);
              this.capabilities.version = serviceTypeVersion;
              this.capabilities.layers = layers;
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error('The capabilities identification service processing went wrong!', error);
            }
          }
        }
      },
    },
  },
  methods: {
    async _createGeoJSONVectorSource(url) {
      if (GeoJSON && VectorSource) {
        const response = await fetch(url);
        if (response.ok) {
          const geojson = await response.json();
          const features = new GeoJSON().readFeatures(geojson);
          const projectionCode = this.layer.map.getView().getProjection().getCode();
          features.forEach((feature) => {
            feature.setId(feature.get('id'));
            feature.getGeometry().transform(this.projection, projectionCode);
          });
          this.vectorSource = new VectorSource({
            features,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error(response.errorMessage);
        }
      }
    },
    async getCapabilities() {
      if (DOMParserExists) {
        const response = await fetch(this.capabilitiesUrl);
        return new DOMParser().parseFromString(await response.text(), 'text/xml');
      }
      return undefined;
    },
  },
};
