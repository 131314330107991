import VlImage from '@govflanders/vl-ui-image/src/vue';
import VlCaption from '@govflanders/vl-ui-caption/src/vue';
import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue';
import VlButton from '@govflanders/vl-ui-button/src/vue';
import nlBE from './locale/nl-BE';

let VlLightbox;
let VlLightboxItem;

if (typeof window !== 'undefined') {
  VlLightbox = require('@govflanders/vl-ui-lightbox/src/vue').default; /* eslint-disable-line */
  VlLightboxItem = require('@govflanders/vl-ui-lightbox/src/vue').VlLightboxItem; /* eslint-disable-line */
}

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  provide() {
    return {
      gallery: this,
    };
  },
  components: {
    VlLightbox,
    VlLightboxItem,
    VlImage,
    VlCaption,
    VlAnnotation,
    VlButton,
  },
  props: {
    modLightbox: Boolean,
    modTeaserA: Boolean,
    modTeaserB: Boolean,
    modTeaserC: Boolean,
    modGrid: Boolean,
    columns: Number,
    modSlider: Boolean,
    viewer: Boolean,
    viewerWithLightbox: Boolean,
    a11yLabelPrevSlideButton: {
      type: String,
      default: 'Vorige slide',
    },
    a11yLabelNextSlideButton: {
      type: String,
      default: 'Volgende slide',
    },
  },
  data() {
    return {
      sliderItems: [],
      selectedSliderItem: null,
    };
  },
  computed: {
    classes() {
      return [
        'vl-gallery',
        {
          'vl-gallery--teaser-a': this.modTeaserA,
          'vl-gallery--teaser-b': this.modTeaserB,
          'vl-gallery--teaser-c': this.modTeaserC,
          'vl-gallery--grid': this.modGrid && !this.modLightbox,
          'vl-gallery--grid-1cols': this.columns === 1 && !this.modLightbox,
          'vl-gallery--grid-2cols': this.columns === 2 && !this.modLightbox,
          'vl-gallery--grid-3cols': this.columns === 3 && !this.modLightbox,
          'vl-gallery--slider': this.modSlider,
          'vl-gallery--slider-with-viewer': this.viewer || this.viewerWithLightbox,
        },
      ];
    },
    lightboxClasses() {
      return [
        'vl-gallery__lightbox',
        {
          'vl-gallery__lightbox--grid': this.modGrid,
          'vl-gallery__lightbox--grid-1cols': this.columns === 1,
          'vl-gallery__lightbox--grid-2cols': this.columns === 2,
          'vl-gallery__lightbox--grid-3cols': this.columns === 3,
        },
      ];
    },
    currentSlideCaption() {
      const currentSlideIndex = this.sliderItems.indexOf(this.selectedSliderItem);
      return `${currentSlideIndex + 1}/${this.sliderItems.length}`;
    },
    currentSlideIndex() {
      return this.sliderItems.indexOf(this.selectedSliderItem);
    },
  },
  mounted() {
    this.sliderItems.forEach((sliderItem, i) => {
      if (this.viewer || this.viewerWithLightbox) {
        sliderItem.isInteractive = true;
      }

      if (i === 0) {
        this.setActiveSlideIndex(0);
      }

      sliderItem.$on('click-gallery-item', () => {
        this.setActiveSlideIndex(this.sliderItems.indexOf(sliderItem));
      });
    });
  },
  methods: {
    setActiveSlideIndex(index) {
      this.selectedSliderItem = this.sliderItems[index];
      this.sliderItems.forEach(item => { item.active = false; });
      this.selectedSliderItem.active = true;
    },
    prevSlide() {
      let currentSlideIndex = this.sliderItems.indexOf(this.selectedSliderItem);
      currentSlideIndex--;

      if (currentSlideIndex >= 0) {
        this.setActiveSlideIndex(currentSlideIndex);
      }
    },
    nextSlide() {
      let currentSlideIndex = this.sliderItems.indexOf(this.selectedSliderItem);
      currentSlideIndex++;

      if (currentSlideIndex <= this.sliderItems.length - 1) {
        this.setActiveSlideIndex(currentSlideIndex);
      }
    },
  },
};
