import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';

import { VlIcon } from '@govflanders/vl-ui-icon/src/vue';
import { VlLink } from '@govflanders/vl-ui-link/src/vue';

import nlBE from './locale/nl-BE';

let Dropzone;

if (typeof window !== 'undefined') {
  // eslint-disable-next-line
  Dropzone = require('nuxt-dropzone');
}

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlLink,
    Dropzone,
  },
  props: {
    maxFiles: [String, Number],
    maxFilesMsg: String,
    maxFilesize: [String, Number],
    maxFilesizeMsg: String,
    url: String,
    allowedFileTypes: String,
    autoProcess: Boolean,
    uploadDestroy: {
      type: Boolean,
      default: true,
    },
    uploadLabel: String,
    uploadDragText: String,
    options: Object,
    id: String,
    modError: Boolean,
    modSuccess: Boolean,
    modDisabled: Boolean,
    name: String,
    uploadFilesHiddenCloseLabel: String,
    uploadInvalidFileType: String,
    uploadResponseError: String,
  },
  data() {
    return {
      opts: null,
      hasFiles: false,
      isDragging: false,
    };
  },
  // Computed properties
  computed: {
    _uploadDragText() {
      return this.uploadDragText || this.$i18n.t('uploadDragText');
    },
    _uploadLabel() {
      return this.uploadLabel || this.$i18n.t('uploadLabel');
    },
    _uploadInvalidFileType() {
      return this.uploadInvalidFileType || this.$i18n.t('uploadInvalidFileType');
    },
    _uploadResponseError() {
      return this.uploadResponseError || this.$i18n.t('uploadResponseError');
    },
    classes() {
      return [
        'vl-upload', {
          'vl-upload--dragging': this.isDragging,
          'vl-upload--error': this.modError,
          'vl-upload--success': this.modSuccess,
          'vl-upload--disabled': this.modDisabled,
        },
      ];
    },
    templateId() {
      return `dropzone-template-${this.id}`;
    },
    dropzoneOptions() {
      // Merge the two ways users can add options
      const userOptions = this.options ? this.options : {};
      const opts = Object.assign(this.opts, userOptions);

      return opts;
    },
    uploadContainerClasses() {
      return [
        {
          'vl-upload__files--has-files': this.hasFiles,
        },
      ];
    },
    uploadContainerId() {
      return `${this.id}-container`;
    },
  },
  methods: {
    fileAdded() {
      this.fileChanged();
    },
    fileChanged() {
      this.hasFiles = this.dzHasFiles(this.$refs.dropzoneRef.dropzone);
    },
    removeFiles() {
      this.$refs.dropzoneRef.dropzone.removeAllFiles(true);

      this.hasFiles = this.dzHasFiles(this.$refs.dropzoneRef.dropzone);
    },
    dzHasFiles(dz) {
      return !!dz.files.length;
    },
    addDefaultOptions(opts) {
      const accessibilityLabel = `${this._uploadLabel} - ${this._uploadDragText}`;
      const maxFileSizeOutput = this.maxFilesize < 1 ? this.maxFilesize * 1000 : this.maxFilesize;
      const maxFileSizeUnit = this.maxFilesize >= 1 || !this.maxFilesize ? 'MB' : 'KB';

      opts.maxFiles = this.maxFiles;
      opts.maxFilesize = this.maxFilesize;
      opts.autoProcessQueue = this.autoProcess;
      opts.url = this.url;
      opts.acceptedFiles = this.allowedFileTypes;
      opts.dictFileTooBig = this.maxFilesizeMsg
                            || this.$i18n.t('uploadMaxFileSize', { size: `${this.maxFilesize ? maxFileSizeOutput : 256}${maxFileSizeUnit}` });
      opts.dictMaxFilesExceeded = this.maxFilesMsg || this.$i18n.t('uploadMaxFilesExceeded', { maxFiles: this.maxFiles });
      opts.dictInvalidFileType = this._uploadInvalidFileType;
      opts.dictResponseError = this._uploadResponseError;
      opts.dictDefaultMessage = `
        <div class="vl-upload__element">
          <div class="vl-upload__element__label">
            <div class="vl-upload__element__button vl-link"
              aria-label="${accessibilityLabel}">
              <span aria-hidden="true" class="vl-vi vl-vi-paperclip"></span>
              <span class="vl-upload__element__button__container">
                ${this._uploadLabel}
              </span>
            </div>
            <small>${this._uploadDragText}</small>
          </div>
        </div>
      `;
      opts.previewTemplate = `
        <div class="vl-upload__file">
          <p class="vl-upload__file__name">
            <span class="vl-upload__file__name__icon vl-vi vl-vi-document" aria-hidden="true"></span>
            <span data-dz-name></span>
            <span class="vl-upload__file__size">
              (<span data-dz-size></span>)
            </span>
          </p>
          <div class="dz-error-message">
            <span data-dz-errormessage></span>
          </div>
          <button type="button" class="vl-upload__file__close vl-link vl-link--icon"
            data-dz-remove>
            <span class="vl-vi vl-vi-cross" aria-hidden="true"></span>
          </button>
        </div>
      `;

      return opts;
    },
  },
  watch: {
    modDisabled(newVal) {
      if (this.$refs.dropzoneRef.dropzone) {
        const dz = this.$refs.dropzoneRef;
        if (newVal) {
          dz.disable();
        } else {
          dz.enable();
        }
      }
    },
  },
  beforeMount() {
    let opts = {};
    opts = this.addDefaultOptions(opts);
    this.opts = pickBy(opts, value => !isNil(value));
  },
  mounted() {
    if (this.$refs.dropzoneRef.dropzone) {
      const dz = this.$refs.dropzoneRef;
      const self = this;

      dz.setOption('previewsContainer', `#${this.id}-container`);
      dz.setOption('hiddenInputContainer', `#${this.id}`);

      dz.$on('vdropzone-thumbnail', (file, dataUrl) => {
        self.$emit('upload-thumbnail', file, dataUrl);
      });

      dz.$on('vdropzone-file-added', function (file) {
        self.$emit('upload-file-added', file);
      });

      dz.$on('vdropzone-files-added', (files) => {
        self.$emit('upload-files-added', files);
      });

      dz.$on('vdropzone-removed-file', (file) => {
        self.fileChanged(file);
        self.$emit('upload-removed-file', file);
      });

      dz.$on('vdropzone-success', (file, response) => {
        self.$emit('upload-success', file, response);
      });

      dz.$on('vdropzone-success-multiple', (file, response) => {
        self.$emit('upload-success-multiple', file, response);
      });

      dz.$on('vdropzone-error', (file, message, xhr) => {
        if (message === this.dropzoneOptions.dictFileTooBig) {
          file.previewTemplate.classList.add('vl-upload__file--error-size');
        }

        self.$emit('upload-error', file, message, xhr);
      });

      dz.$on('vdropzone-error-multiple', (files, message, xhr) => {
        self.$emit('upload-error-multiple', files, message, xhr);
      });

      dz.$on('vdropzone-sending', (file, xhr, formData) => {
        self.$emit('upload-sending', file, xhr, formData);
      });

      dz.$on('vdropzone-sending-multiple', (file, xhr, formData) => {
        self.$emit('upload-sending-multiple', file, xhr, formData);
      });

      dz.$on('vdropzone-complete', (file) => {
        self.$emit('upload-complete', file);
      });

      dz.$on('vdropzone-complete-multiple', (files) => {
        self.$emit('upload-complete-multiple', files);
      });

      dz.$on('vdropzone-canceled', (file) => {
        self.$emit('upload-canceled', file);
      });

      dz.$on('vdropzone-canceled-multiple', (files) => {
        self.$emit('upload-canceled-multiple', files);
      });

      dz.$on('vdropzone-max-files-reached', (files) => {
        self.$emit('upload-max-files-reached', files);
      });

      dz.$on('vdropzone-max-files-exceeded', (file) => {
        self.$emit('upload-max-files-exceeded', file);
      });

      dz.$on('vdropzone-processing', (file) => {
        self.$emit('upload-processing', file);
      });

      dz.$on('vdropzone-processing', (file) => {
        self.$emit('upload-processing', file);
      });

      dz.$on('vdropzone-processing-multiple', (files) => {
        self.$emit('upload-processing-multiple', files);
      });

      dz.$on('vdropzone-upload-progress', (file, progress, bytesSent) => {
        self.$emit('upload-upload-progress', file, progress, bytesSent);
      });

      dz.$on('vdropzone-total-upload-progress', (totaluploadprogress, totalBytes, totalBytesSent) => {
        self.$emit('upload-total-upload-progress', totaluploadprogress, totalBytes, totalBytesSent);
      });

      dz.$on('vdropzone-reset', () => {
        self.$emit('upload-reset');
      });

      dz.$on('vdropzone-queue-complete', () => {
        self.$emit('upload-queue-complete');
      });

      dz.$on('vdropzone-drop', (event) => {
        self.isDragging = false;
        self.$emit('upload-drop', event);
      });

      dz.$on('vdropzone-drag-start', (event) => {
        self.$emit('upload-drag-start', event);
      });

      dz.$on('vdropzone-drag-end', (event) => {
        self.$emit('upload-drag-end', event);
      });

      dz.$on('vdropzone-drag-enter', (event) => {
        self.$emit('upload-drag-enter', event);
      });

      dz.$on('vdropzone-drag-over', (event) => {
        self.isDragging = true;
        self.$emit('upload-drag-over', event);
      });

      dz.$on('vdropzone-drag-leave', (event) => {
        self.isDragging = false;
        self.$emit('upload-drag-leave', event);
      });

      self.$emit('upload-mounted');
    }
  },
  beforeDestroy() {
    if (this.uploadDestroy) {
      this.$refs.dropzoneRef.dropzone.destroy();
    }
  },
};
