<template>
  <span>
    <slot></slot>
  </span>
</template>

<script>
import textFit from 'textfit';

export default {
  props: {
    alignVert: {
      default: false,
      type: Boolean,
    }, // if true, textFit will align vertically using css tables
    alignHoriz: {
      default: false,
      type: Boolean,
    }, // if true, textFit will set text-align: center
    multiLine: {
      default: false,
      type: Boolean,
    }, // if true, textFit will not set white-space: no-wrap
    detectMultiLine: {
      default: true,
      type: Boolean,
    }, // disable to turn off automatic multi-line sensing
    minFontSize: {
      default: 6,
      type: Number,
    },
    maxFontSize: {
      default: 80,
      type: Number,
    },
    reProcess: {
      default: true,
      type: Boolean,
    }, // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
    widthOnly: {
      default: false,
      type: Boolean,
    }, // if true, textFit will fit text to element width, regardless of text height
    alignVertWithFlexbox: {
      default: false,
      type: Boolean,
    }, // if true, textFit will use flexbox for vertical alignment
  },
  data() {
    return {
      observer: null,
      parentEl: null,
    };
  },
  methods: {
    calculate() {
      if (this.$el.parentElement) {
        this.parentEl = this.$el.parentElement;
      }
      textFit(this.parentEl, {
        alignVert: this.alignVert,
        alignHoriz: this.alignHoriz,
        multiLine: this.multiLine,
        detectMultiLine: this.detectMultiLine,
        minFontSize: this.minFontSize,
        maxFontSize: this.maxFontSize,
        reProcess: this.reProcess,
        widthOnly: this.widthOnly,
        alignVertWithFlexbox: this.alignVertWithFlexbox,
      });
    },
  },
  mounted() {
    this.calculate();

    window.addEventListener('resize', this.calculate);

    if ('MutationObserver' in window && this.observer === null) {
      // Create the observer (and what to do on changes...)
      this.observer = new MutationObserver(this.calculate);

      // Setup the observer
      this.observer.observe(
        this.$el,
        { subtree: true, characterData: true },
      );
    }
  },
  beforeDestroy() {
    // Clean up
    this.observer.disconnect();
    window.removeEventListener('resize', this.calculate);
  },
};
</script>
