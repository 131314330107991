<template>
  <component :is="tagName" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      default: 'ul',
    },
    /**
    * Adds a border before and after the accordion-list-items
    */
    modBordered: Boolean,
  },
  computed: {
    classes() {
      return ['vl-accordion-list', {
        'vl-accordion-list--bordered': this.modBordered,
      }];
    },
  },
};
</script>
