import Vue from 'vue';
import forEach from 'lodash/forEach';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'; /* eslint-disable-line */
import FocusLock from 'vue-focus-lock';

import VlCaption from '@govflanders/vl-ui-caption/src/vue/';
import nlBE from './locale/nl-BE';

let PhotoSwipe;
if (typeof window !== 'undefined') {
  PhotoSwipe = require('photoswipe'); /* eslint-disable-line */
}

export default {
  provide() {
    return {
      parent: this,
    };
  },
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    FocusLock,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    closeLabel: String,
    zoomLabel: String,
    previousLabel: String,
    nextLabel: String,
    id: { type: String, default: '1' },
    modFocusLockDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pswp: null,
      gallery: null,
      lightboxItems: [],
      isOpen: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-lightbox',
      ];
    },
    computedCloseLabel() {
      return this.closeLabel || this.$t('lightbox.close');
    },
    computedZoomLabel() {
      return this.zoomLabel || this.$t('lightbox.zoom');
    },
    computedPrevLabel() {
      return this.prevLabel || this.$t('lightbox.prev');
    },
    computedNextLabel() {
      return this.nextLabel || this.$t('lightbox.next');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pswp = this.$refs.pswp;

      this.bindEventListenersToChilds();
    });
  },
  methods: {
    bindEventListenersToChilds() {
      // bind the event listener to all childs
      forEach(this.lightboxItems, (lightboxItem, index) => {
        if (lightboxItem) {
          lightboxItem.$on('click-lightbox-item', () => {
            this.openPhotoSwipe(index, lightboxItem);
          });
        }
      });
    },

    handleClick(direction) {
      this.$emit('click-lightbox-arrow', direction);
    },

    parseThumbnailElements(thumb) {
      let width = 0;
      let height = 0;

      if (vl.util.exists(thumb.lightBoxSize)) {
        const size = thumb.lightBoxSize.split('x');

        width = parseInt(size[0], 10);
        height = parseInt(size[1], 10);
      }

      const VlCaptionClass = Vue.extend(VlCaption);

      const caption = new VlCaptionClass({
        propsData: {
          copyright: thumb.copyright,
          caption: thumb.caption,
        },
      });
      caption.$mount();

      const item = {
        src: thumb.link,
        w: width,
        h: height,
        title: caption.$el.innerHTML,
        msrc: thumb.imgUrl,
        el: thumb.$el,
      };

      return item;
    },

    calculateIdealSize(item, instance) {
      const baseHeight = instance.height;
      const baseWidth = instance.width;
      let calculatedBaseHeight;
      let calculatedBaseWidth;

      const viewportHeight = window.innerHeight;

      if (baseHeight > viewportHeight) {
        calculatedBaseHeight = viewportHeight;
        calculatedBaseWidth = (viewportHeight / baseHeight) * baseWidth;

        item.h = calculatedBaseHeight;
        item.w = calculatedBaseWidth;
      } else {
        item.h = baseHeight;
        item.w = baseWidth;
      }
    },

    openPhotoSwipe(index, galleryElement, disableAnimation, fromURL) {
      this.isOpen = true;
      const vueItems = this.lightboxItems;
      const options = {
        // define gallery index (for URL)
        galleryUID: this.id,
        history: false,
        getThumbBoundsFn: (count) => {
          // See Options -> getThumbBoundsFn section of documentation for more info
          const { thumbnail } = vueItems[count].$refs.thumbnail; // find thumbnail
          const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;

          if (thumbnail) {
            const rect = thumbnail.getBoundingClientRect();
            return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
          }
          return false;
        },
        arrowKeys: true,
        showHideOpacity: true,
      };

      // PhotoSwipe opened from URL
      if (fromURL) {
        if (options.galleryPIDs) {
          // parse real index when custom PIDs are used
          // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
          for (let j = 0; j < vueItems.length; j++) {
            if (vueItems[j].pid === index) {
              options.index = j;
              break;
            }
          }
        } else {
          // in URL indexes start from 1
          options.index = parseInt(index, 10) - 1;
        }
      } else {
        options.index = parseInt(index, 10);
      }

      // exit if index not found
      if (Number.isNaN(options.index)) {
        return;
      }

      if (disableAnimation) {
        options.showAnimationDuration = 0;
      }

      const items = [];

      for (let i = 0; i < vueItems.length; i++) {
        const item = this.parseThumbnailElements(vueItems[i]);
        items.push(item);
      }

      // Pass data to PhotoSwipe and initialize it
      this.gallery = new PhotoSwipe(this.pswp, PhotoSwipeUI_Default, items, options);

      this.gallery.listen('close', () => {
        this.isOpen = false;
      });

      this.gallery.listen('gettingData', (i, item) => {
        const self = this;

        if (!item.w && !item.h) {
          if (item.w < 1 || item.h < 1) {
            const img = new Image();

            img.onload = function () {
              self.calculateIdealSize(item, this);
              self.gallery.updateSize(true);
            };

            img.src = item.src;
          }
        }
      });
      this.gallery.init();
    },
  },
  beforeDestroy() {
    if (this.gallery) {
      this.gallery.close();
    }
  },
};
