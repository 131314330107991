<template>
  <div class="vl-sidebar-header">
    <button class="vl-sidebar-header__toggle" v-if="hasBackLink" @click="toggleLatestElement">
      <vl-icon icon="nav-left" />
      <span class="vl-u-visually-hidden">
        {{ toggleA11yLabel || $t('toggleA11yLabel') }}
      </span>
    </button>
    <vl-title
      v-if="title"
      :tag-name="titleTagName"
      :tag-style="titleTagStyle"
      class="vl-sidebar-header__title">
      <vl-link v-if="hasBackLink" role="button" @click="toggleLatestElement">
        {{ title }}
      </vl-link>
      <template v-else>
        {{ title }}
      </template>
    </vl-title>
    <div class="vl-sidebar-header__content" ref="sidebar-content">
      <slot />
    </div>
  </div>
</template>

<script>
import VlLink from '@govflanders/vl-ui-link/src/vue';
import VlTitle from '@govflanders/vl-ui-titles/src/vue';
import VlIcon from '@govflanders/vl-ui-icon/src/vue';

export default {
  components: {
    VlLink,
    VlTitle,
    VlIcon,
  },
  props: {
    title: String,
    titleTagName: {
      type: String,
      default: 'h5',
    },
    titleTagStyle: {
      type: String,
      default: 'h5',
    },
    toggleA11yLabel: String,
  },
  computed: {
    hasBackLink() {
      return this.activeArray?.length;
    },
  },
  inject: ['activeArray'],
  methods: {
    toggleLatestElement() {
      this.activeArray.pop();
    },
  },
};
</script>
