import { VlImage } from '@govflanders/vl-ui-image/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  inject: {
    gallery: {
      default: null,
    },
  },
  created() {
    if (this.gallery) {
      this.gallery.sliderItems.push(this);
    }
  },
  components: {
    VlImage,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    src: {
      type: String,
      required: true,
    },
    srcset: String,
    sizes: String,
    alt: String,
    link: String,
    caption: String,
    copyright: String,
  },
  data() {
    return {
      active: false,
      isInteractive: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-gallery__item', {
          'vl-gallery__item--active': this.active,
        },
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit('click-gallery-item');
    },
  },
};
