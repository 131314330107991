<template>
  <div role="region" :class="classes">
    <div class="vl-sidebar__image-container" v-if="$slots.image">
      <slot name="image" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    modContainer: Boolean,
  },
  computed: {
    classes() {
      return ['vl-sidebar-content', {
        'vl-sidebar-content--container': this.modContainer,
      }];
    },
  },
};
</script>
