import VlTitle from '@govflanders/vl-ui-titles/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue/';
import VlSearch from '@govflanders/vl-ui-search/src/vue/';
import nlBE from './locale/nl-BE';

if (typeof window !== 'undefined') {
  require('@govflanders/vl-ui-core/src/js/core'); /* eslint-disable-line */
  require('@govflanders/vl-ui-tabs/src/js/tabs'); /* eslint-disable-line */
}

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlTitle,
    VlLink,
    VlSearch,
  },
  props: {
    tagName: {
      type: String,
      default: 'header',
    },
    title: {
      type: String,
      required: true,
    },
    titleLink: String,
    tabs: {
      type: Array,
    },
    modSearch: Boolean,
    searchPlaceholder: String,
    modNeutralActions: Boolean,
    modThemed: Boolean,
    tabsMobileLabel: {
      type: String,
      default: 'Navigatie',
    },
    searchProps: {
      type: Object,
    },
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    classes() {
      return [
        'vl-functional-header',
        {
          'vl-functional-header--has-actions': this.$slots.actions,
          'vl-functional-header--themed': this.modThemed,
        },
      ];
    },
    actionsClasses() {
      return [
        'vl-functional-header__actions',
        {
          'vl-functional-header__actions--neutral': this.modNeutralActions,
        },
      ];
    },
    hasTabs() {
      return !!this.tabs;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if ((typeof vl !== 'undefined' && 'tabs' in vl) && this.hasTabs) {
        vl.tabs.dressAll();
      }
    });
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchValue);
    },
  },
};
