<template>
  <component :is="tagName" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    modBordered: Boolean,
  },
  computed: {
    classes() {
      return ['vl-accordion-list__item'];
    },
  },
};
</script>
