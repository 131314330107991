import { fromLonLat } from 'ol/proj';

import nlBE from './locale/nl-BE';

import VlMapToolsBar from './subcomponents/vl-ol-map-tools-bar';
import VlMapToolsActionGroup from './subcomponents/vl-ol-map-tools-action-group';
import VlMapToolsAction from './subcomponents/vl-ol-map-tools-action';

let Map;
let View;
let MouseWheelZoom;

if (typeof window !== 'undefined') {
  Map = require('ol/Map').default; /* eslint-disable-line */
  View = require('ol/View').default; /* eslint-disable-line */
  MouseWheelZoom = require('ol/interaction').MouseWheelZoom; /* eslint-disable-line */
}

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlMapToolsBar,
    VlMapToolsActionGroup,
    VlMapToolsAction,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    center: {
      type: Array,
      default: () => [4.3113025, 51.0238049],
    },
    zoom: {
      type: Number,
      default: 8,
    },
    id: String,
    modBoxed: Boolean,
    mapZoomable: {
      type: Boolean,
      default: false,
    },
    mapExpandable: {
      type: Boolean,
      default: false,
    },
    mapDisableMouseWheelZoom: {
      type: Boolean,
      default: false,
    },
    expandA11yLabel: String,
    zoomInA11yLabel: String,
    zoomOutA11yLabel: String,
  },
  data() {
    return {
      olMap: null,
    };
  },
  computed: {
    _id() {
      return this.id || `map-${Math.ceil(Math.random() * 1000000000)}`;
    },
    classes() {
      return [
        'vl-ol-map',
        { 'vl-ol-map--boxed': this.modBoxed },
      ];
    },
    fullscreenEnabled() {
      return this.olMap && !!(
        document.fullscreenEnabled
        || document.mozFullScreenEnabled
        || document.msFullscreenEnabled
        || document.webkitSupportsFullscreen
        || document.webkitFullscreenEnabled
      );
    },
    computedExpandA11yLabel() {
      return this.expandA11yLabel || this.$t('action.expand');
    },
    computedZoomInA11yLabel() {
      return this.zoomInA11yLabel || this.$t('action.zoom_in');
    },
    computedZoomOutA11yLabel() {
      return this.zoomOutA11yLabel || this.$t('action.zoom_out');
    },
  },
  methods: {
    _zoom(amount) {
      const view = this.olMap.getView();
      view.animate({
        zoom: view.getZoom() + amount,
      });
    },
    zoomIn() {
      this._zoom(1);
    },
    zoomOut() {
      this._zoom(-1);
    },
    hasFullscreenElement() {
      return !(
        document.fullscreenElement
        || document.webkitFullscreenElement
        || document.webkitFullScreenElement
        || document.mozFullscreenElement
        || document.msFullscreenElement
      );
    },
    requestFullscreen() {
      (
        this.$el.requestFullscreen
        || this.$el.webkitRequestFullscreen
        || this.$el.webkitRequestFullScreen
        || this.$el.mozRequestFullScreen
        || this.$el.msRequestFullscreen
      ).apply(this.$el);
    },
    exitFullscreen() {
      (
        document.exitFullscreen
        || document.webkitExitFullscreen
        || document.webkitExitFullScreen
        || document.mozExitFullscreen
        || document.msExitFullscreen
      ).apply(document);
    },
    expand() {
      if (this.fullscreenEnabled) {
        if (this.hasFullscreenElement()) {
          this.requestFullscreen();
          this._syncMouseWheelZoomInteraction(true);
        } else {
          this.exitFullscreen();
          this._syncMouseWheelZoomInteraction(!this.mapDisableMouseWheelZoom);
        }
      }
    },
    _syncMouseWheelZoomInteraction(active) {
      const mouseWheelZoomInteraction = this?.olMap?.interactions.getArray().find(interaction => interaction instanceof MouseWheelZoom);
      if (mouseWheelZoomInteraction) {
        mouseWheelZoomInteraction.setActive(active);
      }
    },
  },
  mounted() {
    this.olMap = new Map({
      target: this._id,
      controls: [],
      view: new View({
        center: fromLonLat(this.center),
        zoom: this.zoom,
      }),
    });
    this._syncMouseWheelZoomInteraction(!this.mapDisableMouseWheelZoom);
  },
};
