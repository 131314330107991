import { render, staticRenderFns } from "./vl-dynamic-label.vue?vue&type=template&id=06feb205"
import script from "./vl-dynamic-label.js?vue&type=script&lang=js&external"
export * from "./vl-dynamic-label.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports