import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlTitle from '@govflanders/vl-ui-titles/src/vue';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlTitle,
  },
  props: {
    id: String,
    tagName: {
      type: String,
      default: 'div',
    },
    /**
    * Title when accordion is closed
    */
    title: String,
    /**
    * Title tag name
    */
    titleTagName: {
      type: String,
      default: 'span',
    },
    /**
    * Title tag style
    */
    titleTagStyle: {
      type: String,
      default: 'h4',
    },
    /**
    * Title when accordion is open
    */
    titleOpen: String,
    /**
    * Initial state of the accordion
    */
    open: Boolean,
    /**
    * Icon when accordion is closed
    */
    icon: {
      type: String,
      default: 'arrow-right-fat',
    },
    /**
    * Icon when accordion is open
    */
    iconOpen: String,
    /**
    * Displays accordion as 'read-more' variant
    */
    modMore: Boolean,
    /**
    * Adds a background to the accordion content
    */
    modAltBg: Boolean,
    /**
    * Disables the content indent
    */
    modNoIndent: Boolean,
    /**
    * Hide toggle when content is empty
    */
    modHideToggleWhenEmpty: Boolean,
    /**
    * Keep opened accordion state via URL hash
    */
    hashChange: {
      type: Boolean,
      default: false,
    },
    /**
     * Adds an offset-element for URL-hash notification
     */
    modOffset: {
      type: [Boolean, String, Number],
      default: null,
    },
    /**
     * Add overflow, max-height prop is needed
     */
    modOverflowContent: Boolean,
    contentMaxHeight: String,
    modIconRight: Boolean,
    modXsmall: Boolean,
  },
  data() {
    return {
      opened: this.open,
      hasLink: true,
    };
  },
  // Computed properties
  computed: {
    classes() {
      return ['vl-toggle', 'vl-accordion__toggle', {
        'vl-link': this.hasLink && !this.modMore && !this.hideToggle,
        'vl-link--bold': this.hasLink && !this.modMore && !this.hideToggle,
        'vl-title': this.hideToggle,
        'vl-title--h4': this.hideToggle,
        'vl-title--no-space-bottom': this.hideToggle,
        'vl-toggle--more-button': this.modMore,
        'vl-button': this.modMore,
        'vl-button--secondary': this.modMore,
        'vl-toggle--icon-right': this.modIconRight,
      },
      ];
    },
    summaryClasses() {
      return [
        'vl-accordion__summary', {
          'vl-toggle-wrapper--more-button': this.modMore,
          'vl-accordion__summary--no-toggle': this.hideToggle,
          'vl-accordion__summary--suffix': this.hasSuffixSlot,
        },
      ];
    },
    wrapperClasses() {
      return [
        'vl-accordion', 'js-vl-accordion', {
          'js-vl-accordion--open': this.opened,
          'js-vl-accordion--flex-reverse': this.modMore,
        },
      ];
    },
    pannelClasses() {
      return [
        'vl-accordion__panel',
        {
          'vl-accordion__panel--alt': this.modAltBg,
          'vl-accordion__panel--with-footer': !!this.$slots.footer,
          'vl-accordion__panel--no-indent': this.modNoIndent,
          'vl-accordion__panel--overflow': this.modOverflowContent,
          'vl-accordion__panel--xsmall': this.modXsmall,
        },
      ];
    },
    iconClasses() {
      return [
        'vl-accordion__icon',
        'vl-link__icon',
        'vl-toggle__icon', {
          'vl-link__icon--before': !!this.icon,
        },
      ];
    },
    openedString() {
      if (!this.titleOpen) {
        return this.opened ? 'true' : 'false';
      }
      return null;
    },
    closedString() {
      return !this.opened ? 'true' : 'false';
    },
    hasContent() {
      return !!this.$slots.default;
    },
    hideToggle() {
      return !this.hasContent && this.modHideToggleWhenEmpty;
    },
    calculatedIcon() {
      // eslint-disable-next-line
      let icon = this.icon; // don't use object destructuring because that won't work here

      if (this.opened) {
        icon = this.iconOpen ? this.iconOpen : this.icon;
      }
      return icon;
    },
    accordionTitle() {
      // eslint-disable-next-line
      let title = this.title; // don't use object destructuring because that won't work here

      if (this.opened) {
        title = this.titleOpen ? this.titleOpen : this.title;
      }

      return title;
    },
    computedOffset() {
      try {
        return this.modOffset ? Number(this.modOffset) : null;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn({ error }, 'Error in vl-accordion.js - computedOffset');
        return null;
      }
    },
    hasSuffixSlot() {
      return !!this.$slots.suffix;
    },
  },
  methods: {
    accordionToggle() {
      this.opened = !this.opened;

      if (this.opened) {
        this.accordionOpen();
        if (this.id && this.hashChange) {
          window.history.pushState({}, '', `#${this.id}`);
        }
      } else {
        this.accordionClose();
      }
    },
    accordionOpen() {
      this.opened = true;

      this.$emit('open', this.$el);
    },
    accordionClose() {
      this.opened = false;

      this.$emit('close', this.$el);
    },
    onHashChange() {
      if (window.location.hash && this.hashChange) {
        if (`#${this.id}` === window.location.hash) {
          this.accordionOpen();
        }
      }
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.onHashChange();

      window.addEventListener('hashchange', () => {
        this.onHashChange();
      });
    }
  },
  watch: {
    open(isOpen) {
      this.opened = isOpen;
    },
  },
};
